import { getFormattedPrice } from '../../../../sharedModules/getFormattedPrice';
import type { Deal } from '../../../types/Deal';
import getPriceType from '../../../utils/getPriceType';
import { getDisplayName } from '../../getDisplay';
import type Localiser from '../../Localiser';

import productData from './productData';

export default (
  deal: Deal,
  linkText: string,
  language: string,
  translate: Localiser['translate'],
): string => {
  // Use link text if specified
  if (linkText) {
    return linkText;
  }
  const upfrontPrice =
    getFormattedPrice({
      price: deal?.offer?.price,
      currencyIso: deal?.offer?.currency_iso,
      showOfferLabel: false,
      type: getPriceType(deal),
      locale: language,
      translate,
      showPriceRounded: 'no',
    }) || '';
  const monthlyPrice =
    getFormattedPrice({
      price: deal?.contract?.monthly_price || null,
      currencyIso: deal?.offer?.currency_iso,
      showOfferLabel: false,
      type: getPriceType(deal),
      locale: language,
      translate,
      showPriceRounded: 'no',
    }) || '';
  const totalMonthlyPrice =
    getFormattedPrice({
      price:
        (deal?.contract?.length &&
          deal?.contract?.monthly_price &&
          String(Number(deal.contract.monthly_price) * deal.contract.length)) ||
        null,
      currencyIso: deal?.offer?.currency_iso,
      showOfferLabel: false,
      type: getPriceType(deal),
      locale: language,
      translate,
      showPriceRounded: 'no',
    }) || '';
  const hasYearlyPrice = deal?.contract?.yearly_price;
  const yearlyPrice =
    getFormattedPrice({
      price: (deal?.contract && String(deal.contract.yearly_price)) || null,
      currencyIso: deal?.offer?.currency_iso,
      showOfferLabel: false,
      type: getPriceType(deal),
      locale: language,
      translate,
      showPriceRounded: 'no',
    }) || '';
  const data = deal?.contract?.data && productData(deal.contract.data, translate);

  // Or build text based on the deal type
  switch (deal?.product_type) {
    case 3000:
      if (hasYearlyPrice && yearlyPrice && parseInt(deal?.offer?.price, 10) !== 0) {
        return translate('inbodyTextBroadbandYearly', [
          deal?.merchant?.name ?? '',
          deal?.offer?.name,
          String(deal?.contract?.speed),
          yearlyPrice,
          upfrontPrice,
        ]);
      }
      if (hasYearlyPrice && yearlyPrice) {
        return translate('inbodyTextBroadbandYearlyFreeUpfront', [
          deal?.merchant?.name ?? '',
          deal?.offer?.name,
          String(deal?.contract?.speed),
          yearlyPrice,
        ]);
      }

      // E.g. BT Unlimited Infinity and Weekend Calls with up to 52Mb for only £29.99 a month
      // and £9.99 activation fee
      if (parseInt(deal?.offer?.price, 10) !== 0) {
        return translate('inbodyTextBroadbandMonthly', [
          deal?.merchant?.name ?? '',
          deal?.offer?.name,
          String(deal?.contract?.speed),
          monthlyPrice,
          upfrontPrice,
        ]);
      }

      // E.g. BT Unlimited Infinity and Weekend Calls with up to 52Mb for only £29.99 a month
      // and no activation fee
      return translate('inbodyTextBroadbandMonthlyFreeUpfront', [
        deal?.merchant?.name ?? '',
        deal?.offer?.name,
        String(deal?.contract?.speed),
        monthlyPrice,
      ]);
    case 2500:
      // E.g. GoDaddy Ultimate for £6.99 per month
      if (hasYearlyPrice && yearlyPrice) {
        return translate('inbodyTextSubscriptionsYearly', [getDisplayName(deal), yearlyPrice]);
      }

      // Lifetime
      // E.g. KeepSolid VPN Unlimited for $149.99
      if (deal?.contract?.length && deal.contract.length === 999999) {
        return translate('inbodyTextVPNLifetime', [upfrontPrice]);
      }

      // E.g. IPVanish 6 months for $30 ($5 per month)
      return translate('inbodyTextVPNMonthly', [
        String(deal?.contract?.length),
        monthlyPrice,
        totalMonthlyPrice,
      ]);
    case 2000:
      // E.g. Apple iPhone X (64GB Silver) for £185 upfront with 10GB of data for only £49 per
      // month from Mobiles.co.uk
      if (hasYearlyPrice && yearlyPrice) {
        return translate('inbodyTextContractsYearly', [
          getDisplayName(deal),
          upfrontPrice,
          data || '',
          yearlyPrice,
          deal?.merchant?.name ?? '',
        ]);
      }
      return translate('inbodyTextContractsMonthly', [
        getDisplayName(deal),
        upfrontPrice,
        data || '',
        monthlyPrice,
        deal?.merchant?.name ?? '',
      ]);
    default:
      // Retail
      // E.g. Apple iPad Air 2 (16GB) at Amazon Marketplace for £320.30
      return translate('inbodyTextRetail', [
        getDisplayName(deal),
        deal?.merchant?.name ?? '',
        upfrontPrice,
      ]);
  }
};
